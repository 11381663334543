import React, { useRef, useState } from "react";
import "./App.css";

import outpostLogo from "./assets/images-video/OutPost-Logo.png";
import insta from "./assets/images-video/OP-social-05.png";
import videoSrc from "./assets/images-video/desktop-title.mp4";
import videoMSrc from "./assets/images-video/mobile.mp4";

import { Player } from "@lottiefiles/react-lottie-player";
import lottieAnimation from "./assets/json/scroll.json";

import mail from "./assets/images-video/OP-social-04.png";
import youtube from "./assets/images-video/youtube.svg";

function App() {
  const [mute, setMute] = useState(true);
  const logoRef = useRef();
  const vidRef = useRef();
  const triggerScroll = () => {
    window.scrollTo({
      top: logoRef.current.offsetTop - 20,
      behavior: "smooth",
      block: "start"
    })
    vidRef.current.loop = true;
    vidRef.current.play();

  };
  return (
    <>

      <div className="video-section">
        <video
          ref={vidRef}
          className="video"
          playsInline
          muted={mute}
          autoPlay
          onEnded={triggerScroll}
          alt="All the devices"
          src={window.innerWidth > 500 ? videoSrc : videoMSrc}
          onClick={() => setMute(!mute)}
        />
        <Player
          autoplay
          loop
          src={lottieAnimation}
          style={window.innerWidth > 500 ? { height: "120px", width: "120px" } : { height: "70px", width: "70px" }}
        ></Player>
      </div>

      <img
        ref={logoRef}
        src={outpostLogo}
        alt="OutPostLOGO"
        width="150"
        className="outPostLogo"
      />
      <div className="title">re-discover kanha like never before</div>
      <p>Imagine a wildlife outpost that seasoned naturalists, trained across some of the finest jungle reserves, would cherish enough to make their home and share with fellow nature-lovers.</p>
      <p>This has been the inspiration for Outpost 12.</p>
      <p>
        It is a forest-first habitat and a natural extension of the wild and woody grasslands at the Tiger reserve of Kanha, Madhya Pradesh's largest national park. Unique in its interpretation of
        Kanha, the bio lodge pays homage to everything natural and indigenous, while remaining earth-conscious in its practices.
      </p>
      <p>Located by the side of the beautiful Banjar river, this hideaway has much to surprise nature lovers and wildlife seekers with.</p>
      <h4 className="comming-soon">COMING SOON</h4>
      <p>
        <a className="brochure-lk" href="https://assets.sinali.in/outpost12/outpost12-brochure.pdf" target="_blank" rel="noreferrer">Download our brochure</a>
      </p>      
      <div className="footer-outer">
        <a href="mailto:outpost12@sinali.in" target="_blank" rel="noreferrer">
          <img
            src={mail}
            alt="Social"
            height="17"
          />
        </a>
        <a href="https://www.instagram.com/sinali.experiences" target="_blank" rel="noreferrer">
          <img
            src={insta}
            alt="Social"
            height="17"
          />
        </a>
        <a href="https://www.youtube.com/@SinaliExperiences" target="_blank" rel="noreferrer">
          <img
            src={youtube}
            alt="Social"
            height="17"
          />
        </a>
      </div>
      <div className="sinali-exp-cont">
        <a className="sinali-exp" href="https://www.sinali.in" target="_blank" rel="noreferrer">A Sinali Experience</a>
        <script defer src="https://static.cloudflareinsights.com/beacon.min.js" data-cf-beacon='{"token": "bc6f7066e64043cb874503aed9f8abd4"}'></script>
      </div>
    </>
  );
}

export default App;
